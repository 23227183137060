import { useContext, useEffect, useState, useMemo } from 'react'
import { Fraction, JSBI } from '@arec/sdk'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonConfirmed } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import { StyledPageCard } from '../../components/earn/styled'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { useRECIssuanceContract } from '../../hooks/useContract'

import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { shortenCID } from '../../utils'

import { useWeb3React } from '@web3-react/core'

import { M } from '../../components/ARecIssuance'
import { Container } from '../../components/CurrencyInputPanel'
import { DateTime } from 'luxon'
import { RECRequest } from '../../state/issuance/hooks'

import { MouseoverTooltip } from '../../components/Tooltip'
import { ExternalLink } from 'theme'

import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import Loader from '../../components/Loader'
import { ExternalLink as LinkIcon, CornerDownLeft, CornerRightUp } from 'react-feather'
import { useMedia } from 'react-use'
import { useArecCount, useMintARECHash, useQuickArecId } from '../../state/arec/hooks'
import { useFontSize } from 'hooks/useWindowSize'
import { REC_STATUS, RECData } from '../../state/issuance/hooks'

import { IssuanceHelpInfo, ARECContainer, title, SwapLink } from './IssuanceSteps'

export function LiquidizeSteps({ arecNFTInfo }: { arecNFTInfo: RECData }) {
  const { account, chainId } = useWeb3React()
  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 540px)')

  const { quickArecId, updateQuickArecNFTId } = useQuickArecId()

  const { updatetxHash } = useArecCount()

  const { updateMintARECNFTHash } = useMintARECHash()

  const arkreenRECIssuanceContract = useRECIssuanceContract(true)

  //console.log('AAAAAAAAAAAA', account, chainId, arecNFTInfo)

  const [{ showConfirm, txnToConfirm }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    IssueErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    IssueErrorMessage: undefined,
    txHash: undefined
  })

  const addTransaction = useTransactionAdder()

  const [isRequesting, setIsRequesting] = useState<boolean>(false)

  async function handleRECLiquidize() {
    if (!arkreenRECIssuanceContract || quickArecId === undefined) return

    const ARECID = quickArecId
    const ARECIDString = '0000'.concat(ARECID.toString())
    const ARECIDStr = ARECIDString.substring(ARECIDString.length - 4)

    setIsRequesting(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, IssueErrorMessage: undefined, txHash: undefined })
    await arkreenRECIssuanceContract.estimateGas['liquidizeREC'](ARECID)
      .then(async estimatedGasLimit => {
        await arkreenRECIssuanceContract
          .liquidizeREC(ARECID, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then((response: TransactionResponse) => {
            setIsRequesting(false)
            setIsLiquidizing(true)
            addTransaction(response, {
              summary: `Liquidize AREC ID: ${ARECIDStr}`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              IssueErrorMessage: undefined,
              txHash: response.hash
            })
            updatetxHash(response.hash)
          })
          .catch((error: any) => {
            setIsRequesting(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`AREC Liquidize failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`AREC Liquidize failed: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsRequesting(false)
        console.log('Error of AREC Liquidize tx:', error)

        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message

        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          IssueErrorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  const recStatus =
    arecNFTInfo?.status === REC_STATUS.Pending
      ? 'Pending'
      : arecNFTInfo?.status === REC_STATUS.Certified
      ? 'Certified'
      : arecNFTInfo?.status === REC_STATUS.Cancelled
      ? 'Cancelled'
      : arecNFTInfo?.status === REC_STATUS.Rejected
      ? 'Rejected'
      : arecNFTInfo?.status === REC_STATUS.Liquidized
      ? 'Liquidized'
      : ' '

  const arecRECAmountString = useMemo(() => {
    if (!arecNFTInfo || arecNFTInfo.startTime === 0) return undefined
    return new Fraction(arecNFTInfo?.amountREC.toString(), JSBI.BigInt(1000000)).toFixed(3)
  }, [arecNFTInfo])

  const arecStartDateString = useMemo(() => {
    if (!arecNFTInfo || arecNFTInfo.startTime === 0) return undefined
    return DateTime.fromSeconds(arecNFTInfo.startTime)
      .toUTC()
      .toFormat('yyyy-MM-dd')
  }, [arecNFTInfo])

  const arecEndDateString = useMemo(() => {
    if (!arecNFTInfo || arecNFTInfo.startTime === 0) return undefined
    return DateTime.fromSeconds(arecNFTInfo.endTime)
      .toUTC()
      .toFormat('yyyy-MM-dd')
  }, [arecNFTInfo])

  const mintAmountString = useMemo(() => {
    if (!arecNFTInfo || arecNFTInfo.startTime === 0 || arecNFTInfo.status < REC_STATUS.Certified) return undefined
    if (arecNFTInfo.amountREC.lte(1000_000)) {
      return new Fraction(arecNFTInfo.amountREC.toString(), JSBI.BigInt(1000_000))
        .toFixed(3, { groupSeparator: ',' })
        .concat(' mART')
    }
    return new Fraction(arecNFTInfo.amountREC.toString(), JSBI.BigInt(1000_000_000))
      .toFixed(3, { groupSeparator: ',' })
      .concat(' ART')
  }, [arecNFTInfo])

  const [isMinting, setIsMinting] = useState<boolean>(false)
  const [isLiquidizing, setIsLiquidizing] = useState<boolean>(false)

  const { font14, font16, font20, font24 } = useFontSize()
  const font24Str = font24.toString() + 'px'

  const [phase, setPhase] = useState<number | undefined>(undefined)

  useEffect(() => {
    function movePhaseUp(newPhase: number) {
      if (phase === undefined || newPhase > phase) setPhase(newPhase)
    }

    if (!chainId || !account || !arecNFTInfo) movePhaseUp(0)
    else {
      if (isMinting) setIsMinting(false)
      if (arecNFTInfo.status < REC_STATUS.Certified) movePhaseUp(4)
      else if (arecNFTInfo.status == REC_STATUS.Certified) movePhaseUp(5)
      else if (arecNFTInfo.status == REC_STATUS.Liquidized) {
        movePhaseUp(6)
        setIsLiquidizing(false)
      } else movePhaseUp(4)
    }
  }, [phase, chainId, account, arecNFTInfo, isMinting, setIsMinting])

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const [cID, shortCID, urlCID] = useMemo(() => {
    const cID = arecNFTInfo?.cID
    const urlCID = arecNFTInfo?.url
    return cID ? [cID, shortenCID(cID), urlCID] : ['', '', '']
  }, [arecNFTInfo])

  return (
    <AppBody style={{ marginTop: below540 ? '30px' : '60px' }}>
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={phase !== undefined ? title[phase] : title[0]}>
          {chainId && (
            <QuestionHelper bkgOff={true} text={'Quick steps to mint ART token'} info={<IssuanceHelpInfo />} />
          )}
        </PageHeader>
        <Wrapper id="issuance-page">
          <Row style={{ padding: '4px', justifyContent: 'left', marginTop: '-0.5rem' }}>
            <Text fontWeight={700} fontSize={font16} color={theme.primary1}>
              Please follow the steps to mint ART quickly
            </Text>
          </Row>
          <AutoColumn gap={'md'}>
            <Container>
              <ARECContainer>
                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      AREC NFT ID:
                    </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>This is ID of the AREC NFT just minted.</>} />
                  </RowFixed>
                  <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text2}>
                    {quickArecId}
                  </Text>
                </RowBetween>

                {phase !== undefined && phase >= 5 && (
                  <RowBetween align="center" height={font24Str}>
                    <RowFixed>
                      <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                        AREC Serial No:
                      </Text>
                      <QuestionHelper
                        bkgOff={true}
                        small={'s'}
                        info={<>This is the unique serial number of the AREC NFT certified by the issuer.</>}
                      />
                    </RowFixed>
                    <Text
                      lineHeight={font24Str}
                      fontWeight={700}
                      fontSize={font14}
                      color={arecNFTInfo?.status === REC_STATUS.Certified ? theme.primaryText1 : theme.text1}
                    >
                      {arecNFTInfo?.serialNumber}
                    </Text>
                  </RowBetween>
                )}

                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      AREC Status:
                    </Text>
                    <QuestionHelper
                      bkgOff={true}
                      small={'s'}
                      info={
                        <>
                          This is the status of the AREC NFT, which may be <M>Pending, Certified, Rejected</M> and so
                          on. <M>Certified</M> AREC can be liquidized.
                        </>
                      }
                    />
                  </RowFixed>
                  <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                    {recStatus}
                  </Text>
                </RowBetween>

                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      AREC Start Date:
                    </Text>
                    <QuestionHelper
                      bkgOff={true}
                      small={'s'}
                      info={<>This is the start date of renewable energy gereration of the AREC NFT.</>}
                    />
                  </RowFixed>
                  <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                    {arecStartDateString}
                  </Text>
                </RowBetween>

                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      AREC End Date:
                    </Text>
                    <QuestionHelper
                      bkgOff={true}
                      small={'s'}
                      info={<>This is the last date of renewable energy gereration of the AREC NFT.</>}
                    />
                  </RowFixed>
                  <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                    {arecEndDateString}
                  </Text>
                </RowBetween>

                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      Total RE Amount:
                    </Text>
                    <QuestionHelper
                      bkgOff={true}
                      small={'s'}
                      info={<>This is the total renewable energy amount included in the AREC NFT.</>}
                    />
                  </RowFixed>
                  <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                    {arecRECAmountString} KWH
                  </Text>
                </RowBetween>

                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      AREC CID:
                    </Text>
                    <QuestionHelper
                      bkgOff={true}
                      small={'s'}
                      info={<>This the CID of the renewable energy generation data in IPFS system.</>}
                    />
                  </RowFixed>

                  <div>
                    {urlCID && (
                      <ExternalLink href={urlCID} style={{ paddingRight: '6px' }}>
                        <LinkIcon size={font14} color={theme.text2} />
                      </ExternalLink>
                    )}
                    <MouseoverTooltip info={<>{cID}</>}>
                      <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                        {shortCID}
                      </Text>
                    </MouseoverTooltip>
                  </div>
                </RowBetween>
              </ARECContainer>
            </Container>

            {phase !== undefined && phase === 5 && (
              <AutoColumn gap="0px" style={{ padding: '0rem 0.8rem 0rem 0.8rem' }}>
                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                      Liquidize To Mint ART Token:
                    </Text>
                  </RowFixed>
                </RowBetween>
                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                      ART Token To Mint:
                    </Text>
                    <QuestionHelper
                      bkgOff={true}
                      small={'s'}
                      info={<>The AREC NFT will be liquidized, and equivalent amount of ART token will be mint.</>}
                    />
                  </RowFixed>
                  <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                    {mintAmountString}
                  </Text>
                </RowBetween>
              </AutoColumn>
            )}
            {phase !== undefined && phase === 6 && (
              <AutoColumn gap="0px" style={{ padding: '0rem 0.8rem 0rem 0.8rem' }}>
                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                      AREC NFT <M>#{quickArecId}</M> is liquidized:
                    </Text>
                  </RowFixed>
                </RowBetween>
                <RowBetween align="center" height={font24Str}>
                  <RowFixed>
                    <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                      ART Token Minted:
                    </Text>
                    <QuestionHelper
                      bkgOff={true}
                      small={'s'}
                      info={<>This is equivalent to renewable energy amount of the liquidized AREC NFT.</>}
                    />
                  </RowFixed>
                  <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                    {mintAmountString}
                  </Text>
                </RowBetween>
              </AutoColumn>
            )}
          </AutoColumn>
          <BottomGrouping>
            <RowBetween>
              {phase === 4 && (
                <ButtonError disabled={true} id="liquidize-button">
                  <Text fontSize={font20} fontWeight={500}>
                    {arecNFTInfo?.status === REC_STATUS.Certified ? 'AREC NFT confirmed' : 'Waiting Confirmation'}
                    {arecNFTInfo?.status !== REC_STATUS.Certified && (
                      <Loader fontSize={font24} stroke={theme.white} style={{ marginLeft: '12px' }} />
                    )}
                  </Text>
                </ButtonError>
              )}
              {phase === 5 && (
                <ButtonError
                  disabled={isRequesting || isLiquidizing}
                  onClick={() => handleRECLiquidize()}
                  id="liquidize-button"
                >
                  <Text fontSize={font20} fontWeight={500}>
                    {isRequesting || isLiquidizing ? 'Liquidizing AREC' : 'Liquidize AREC'}
                  </Text>
                  {isRequesting || isLiquidizing ? (
                    <Loader fontSize={font24} stroke={theme.white} style={{ marginLeft: '12px' }} />
                  ) : (
                    <CornerDownLeft size={font20} stroke={theme.white} style={{ marginLeft: '12px' }} />
                  )}
                </ButtonError>
              )}
              {phase != undefined && phase >= 6 && (
                <ButtonConfirmed
                  onClick={() => {
                    if (!!chainId && !!account) {
                      updateMintARECNFTHash(chainId, account, undefined)
                      updateQuickArecNFTId(chainId, account, undefined)
                    }
                    setPhase(undefined)
                  }}
                  id="liquidize-button"
                >
                  <Text fontSize={font20} fontWeight={500}>
                    Mint ART Completed
                  </Text>
                  <CornerDownLeft size={font20} stroke={theme.white} style={{ marginLeft: '12px' }} />
                </ButtonConfirmed>
              )}
            </RowBetween>
            {phase != undefined && phase >= 6 && (
              <SwapLink href={'https://www.greenbtc.club/swap'}>
                <ButtonConfirmed margin="10px 0px 0px">
                  <Text fontSize={font20} fontWeight={500}>
                    Green BTC with ART
                  </Text>
                  <CornerRightUp size={font20} stroke={theme.white} style={{ marginLeft: '12px' }} />
                </ButtonConfirmed>
              </SwapLink>
            )}
          </BottomGrouping>
        </Wrapper>
      </StyledPageCard>
    </AppBody>
  )
}
